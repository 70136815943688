/* eslint-disable  @typescript-eslint/no-misused-promises */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observe, useObserver, viewportWidth } from 'react-ui-observer'
import { useRouter } from 'next/router'
import { BLOCKS } from '@contentful/rich-text-types'
import axios from 'axios'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { colors } from 'bl-common/src/constants/colors'
import { Amount } from 'bl-common/src/elements/Amount'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { ButtonLink } from 'bl-common/src/richText/RichTextBlocks'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { Section } from 'bl-common/src/units/Section/Section'

import { CheckboxInput, Input } from './fields'
import {
  Container,
  ContainerInner,
  Content,
  EmailWrap,
  ErrorIconWrapper,
  Form,
  Loader,
  Message,
  MessageDialogWrapper,
  StyledButton,
  SuccessIconWrapper,
} from './styles'
import * as styles from './styles'
import { getErrorMessage, validateInput } from './utils'

const documentOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_, children) => (
      <Type
        preset="headlineLarge"
        as="h2"
        bottom={{ xs: 1, md: 1 }}
        top={{ xs: 0 }}
      >
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_6]: (_, children) => (
      <Type
        preset="text"
        bottom={{ xs: 1, md: 1 }}
        top={{ xs: 2, md: 3 }}
        maxWidth={550}
      >
        {children}
      </Type>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Type preset="labelLarge" maxWidth={550} weight="light">
        {children}
      </Type>
    ),
    [BLOCKS.HR]: () => <styles.Divider />,
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { sys, fields } = node.data.target
      switch (sys.contentType.sys.id) {
        case 'ctaButton':
          return fields.displayAsLink ? (
            <ButtonLink
              textColor={fields.textColor}
              to={fields.link}
              emailTrigger={fields.emailTrigger}
              weight="bold"
              paddingSize="small"
            >
              {fields.text}
            </ButtonLink>
          ) : (
            <StyledButton
              paddingSize={fields.paddingSize}
              preset={fields.color}
              textColor={fields.textColor}
              to={fields.link}
              maxWidth={373}
              top={{ xs: 1.5, md: 2.5 }}
              emailTrigger={fields.emailTrigger}
            >
              {fields.text}
            </StyledButton>
          )
        case 'prices':
          return (
            <Type
              preset="text"
              lineHeight={2}
              weight="bold"
              color={colors.deepBlue}
              top={{ xs: 1 }}
            >
              <Amount
                value={fields.isk}
                format={fields.priceFormat}
                useSymbol
              />
            </Type>
          )
        default:
          return null
      }
    },
  },
}

const SuccessIcon = () => (
  <SuccessIconWrapper>
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6133 16.65L19.8338 27.7942L36.0196 6.69971"
        stroke="#454647"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M37.3466 16.2521C37.6119 17.4461 37.7446 18.7065 37.7446 19.9668C37.7446 29.7844 29.7844 37.7446 19.9668 37.7446C10.1493 37.7446 2.18905 29.7844 2.18905 19.9668C2.18905 10.1493 10.1493 2.18905 19.9668 2.18905C22.7529 2.18905 25.4063 2.8524 27.7944 3.9801L29.1211 2.18905C26.4013 0.79602 23.2836 0 19.9668 0C8.95522 0 0 8.95522 0 19.9668C0 30.9784 8.95522 39.9337 19.9668 39.9337C30.9784 39.9337 39.9337 30.9784 39.9337 19.9668C39.9337 17.9104 39.602 15.9867 39.0713 14.1294L37.3466 16.2521Z"
        fill="#454647"
      />
    </svg>
  </SuccessIconWrapper>
)

const ErrorIcon = () => (
  <ErrorIconWrapper>
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11"
        cy="11"
        r="10"
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path fill="none" d="M5.7,16l10,-10M5.7,6l10,10" />
    </svg>
  </ErrorIconWrapper>
)

export const NewsletterSignupFormSection = props => {
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<{
    firstName?: unknown
    lastName?: unknown
    email?: unknown
    consent?: unknown
  }>({})
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [successSecondary, setSuccessSecondary] = useState('')

  const router = useRouter()
  const { i18n } = useTranslation()

  const validation = form => {
    const inputs = form.getElementsByTagName('input')
    let valid = true
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i]
      const errors = validateField(input)
      if (Object.keys(errors[input.name]).length !== 0) valid = false
    }
    return valid
  }

  const validateField = input => {
    errors[input.name] = Object.keys(validateInput(input)).reduce(
      (obj, key) => {
        switch (key) {
          case 'required':
            obj[key] =
              props.section.fields.formRequiredFieldEmptyError ||
              'This field is required'
            break
          case 'invalidEmail':
            obj[key] =
              props.section.fields.formEmailInvalidError ||
              'This is not a valid email address'
            break
          default:
            obj[key] = 'Invalid field'
        }
        return obj
      },
      {}
    )
    setErrors(errors)
    return errors
  }

  const submitForm = async () => {
    setSubmitted(true)
    const form = document.getElementById(
      'mc-embedded-subscribe-form'
    ) as HTMLFormElement
    if (!validation(form)) {
      return false
    }

    setLoading(true)
    const data = {} as { $consent?: string[]; locale?: string }
    for (let i = 0; i < form.elements.length; i++) {
      const el = form.elements[i] as HTMLInputElement

      if (el.name === 'consent') {
        data.$consent = ['email']
      } else {
        data[el.name] = el.value
      }
    }

    data.locale = i18n.language

    try {
      const {
        section: {
          fields: { successPagePath },
        },
      } = props

      await axios({
        method: 'post',
        url: '/api/newsletter-signup',
        data,
      })

      if (successPagePath) {
        router.push(successPagePath)
      } else {
        setSuccess(props.section.fields.formSubmissionSuccess)
        setSuccessSecondary(props.section.fields.formSubmissionSuccessSecondary)
      }
    } catch (e) {
      setError(getErrorMessage(e.response.data.message, props.section.fields))
    } finally {
      form.reset()
      setSubmitted(false)
      setLoading(false)
      setErrors({})
    }
  }

  const {
    section: {
      fields: {
        formConsentText,
        formSecondConsentText,
        formEmailLabel,
        formEmailPlaceholder,
        formSubmitButtonText,
        formTextContent,
        formFirstNameLabel,
        formFirstNamePlaceholder,
        formLastNameLabel,
        formLastNamePlaceholder,
        textContent,
        id: slug,
      },
      sys: { id },
    },
  } = props

  // TODO: Switch to useBreakpoints
  const isMobile = useObserver(
    observe(viewportWidth(), width => width < breakpoints.md)
  )

  const onlyEmail = !formFirstNameLabel && !formLastNameLabel
  return (
    <Section top={{ xs: 2, md: 4 }} bottom={{ xs: 2, md: 4 }} id={slug}>
      <Container>
        <ContainerInner>
          {success ? (
            <MessageDialogWrapper>
              <Type
                preset="headlineLarge"
                as="h2"
                bottom={{ xs: 1, md: 1 }}
                top={{ xs: 0 }}
                textAlign="center"
              >
                <SuccessIcon />
                {success}
              </Type>
              {successSecondary && (
                <Type preset="labelLarge" weight="light" textAlign="center">
                  {successSecondary}
                </Type>
              )}
            </MessageDialogWrapper>
          ) : (
            <>
              <Content>
                {!!textContent && (
                  <RichTextRenderer
                    document={textContent}
                    customOptions={documentOptions}
                  />
                )}
              </Content>
              <Form
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                onSubmit={e => {
                  e.preventDefault()
                  submitForm()
                }}
                noValidate
              >
                {formFirstNameLabel && (
                  <Input
                    id="firstName"
                    labelText={formFirstNameLabel}
                    placeholder={formFirstNamePlaceholder}
                    disabled={loading}
                    name="firstName"
                    type="text"
                    error={errors.firstName}
                    submitted={submitted}
                    validate={validateField}
                    required
                  />
                )}
                {formLastNameLabel && (
                  <Input
                    id="lastName"
                    labelText={formLastNameLabel}
                    placeholder={formLastNamePlaceholder}
                    disabled={loading}
                    name="lastName"
                    type="text"
                    required
                    error={errors.lastName}
                    submitted={submitted}
                    validate={validateField}
                  />
                )}
                {!isMobile && onlyEmail ? (
                  <>
                    <EmailWrap>
                      <Input
                        id="email"
                        labelText={formEmailLabel}
                        placeholder={formEmailPlaceholder}
                        disabled={loading}
                        name="email"
                        type="email"
                        error={errors.email}
                        submitted={submitted}
                        validate={validateField}
                      />
                      <Button
                        type="submit"
                        disabled={loading}
                        paddingSize="small"
                      >
                        {loading === true ? <Loader /> : formSubmitButtonText}
                      </Button>
                    </EmailWrap>
                    {formTextContent && (
                      <Type size={{ xs: 12, md: 15 }} bottom={{ md: 1 }}>
                        {formTextContent}
                      </Type>
                    )}
                    <CheckboxInput
                      id="consent"
                      labelText={formConsentText}
                      disabled={loading ? 'disabled' : ''}
                      error={errors.consent}
                      submitted={submitted}
                      validate={validateField}
                    />
                  </>
                ) : (
                  <>
                    <Input
                      id="email"
                      labelText={formEmailLabel}
                      placeholder={formEmailPlaceholder}
                      disabled={loading}
                      name="email"
                      type="email"
                      error={errors.email}
                      submitted={submitted}
                      validate={validateField}
                    />
                    {formTextContent && (
                      <Type size={{ xs: 12, md: 15 }} bottom={{ md: 1 }}>
                        {formTextContent}
                      </Type>
                    )}
                    {formConsentText && (
                      <CheckboxInput
                        id="consent"
                        labelText={formConsentText}
                        disabled={loading ? 'disabled' : ''}
                        error={errors.consent}
                        submitted={submitted}
                        validate={validateField}
                        required
                      />
                    )}
                    {formSecondConsentText && (
                      <CheckboxInput
                        id="secondConsent"
                        labelText={formSecondConsentText}
                        disabled={loading ? 'disabled' : ''}
                        error={errors.consent}
                        submitted={submitted}
                        validate={validateField}
                        required
                      />
                    )}

                    <StyledButton type="submit" disabled={loading}>
                      {loading === true ? <Loader /> : formSubmitButtonText}
                    </StyledButton>
                  </>
                )}
                {error && (
                  <MessageDialogWrapper>
                    <div className="error">
                      <Message>
                        <ErrorIcon /> <div>{error}</div>
                      </Message>
                    </div>
                  </MessageDialogWrapper>
                )}
                <input name="id" type="hidden" value={id} />
              </Form>
            </>
          )}
        </ContainerInner>
      </Container>
    </Section>
  )
}

export default NewsletterSignupFormSection
